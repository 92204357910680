@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.close {
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  position: absolute;
  z-index: 100; 
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;

}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  z-index: 100;
  background-color: $main-bg;
  padding: 5px 20px;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  margin-right: 200px;
  
  @include md {
    position: fixed;
    z-index: 9999;
    width: max-content;
    background-color: $main-bg ;
    color: #000;
  }

  .hamburger-icon {
    padding-top: 17px;
    padding-left: 30px;
    cursor: pointer;
    left: 20px;
    z-index: 10;
    margin-bottom: 90px;
    color: #fff;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 700;
      color: $soft-color;
      text-transform: uppercase;
    }
    .active {
      background-color: $buttom-color; /* Color de fondo cuando está activo */
      color: white; /* Color del texto cuando está activo */
    }
    

    .listItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;
      color: #fff;

    
  

      &:hover {
        background-color: $soft-bg;
      }

      .listItemTitle {
        @include lg {
        }
      }
    }
  }
}