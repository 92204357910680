// background
$main-bg: #673AB7;
$soft-bg: #d1c4e983;
$dark-bg: #ffffff;

//TEXT
$main-color: #000;
$soft-color: #ffffff;
$border-color:#E040FB;
$dark-color: #2f062b;

// BuTTOM

$buttom-color: #E040FB;
$button-hover:#851199