@import "../../styles/responsive.scss";

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  height: 485px;  
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  thead, 
  th {
    position: sticky;
    top: 0;
    background-color: #fff; 
    font-size: 18px;
    z-index: 2;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    word-wrap: break-word; 
    white-space: normal; 
    text-align: center;
    vertical-align: middle;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody tr:nth-child(even) {
    background-color: #f1f1f1;
  }
}

@include md {
  .Paper{
    width: 100vw;
  }
  .table-container {
    height:calc(100vh - 300px) ; 

   
    th,
    td {
      font-size: 0.9rem;
      padding: 6px;
    }
  }

  .pagination {
    button,
    select {
      font-size: 0.9rem;
      padding: 5px;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
  z-index: 1; 

  button,
  select {
    padding: 5px 10px;
    margin: 0 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}
