@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.item {

  .button {
    width: 150px;
    background-color: $buttom-color;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 21px;
  }

  .button:hover {
    background-color: #f560e3;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    h1 {
      margin-top: 10px;
    }

    button {
      padding: 5px;
      cursor: pointer;
    }
  }
}



