@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.customers {

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;

        h1 {
            color: #fff;
            margin-top: 10px;
        }

        .button {
            width: 70px;
            background-color: $buttom-color;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .button:hover {
            background-color: #f560e3;
        }
    }

    .buttons {
        @include md {
            display: flex;
            flex-direction: column;
        }

        .buttons-edit {
            min-width: 1px !important;
            color: $buttom-color;
        }

        .buttons-deleted {
            color: red;
            min-width: 1px !important;
        }
    }
}