@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.loading-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
}

.loading-m-container {
    position: relative;
}

.loading-m {
    font-size: 60px;
    font-weight: bold;
    animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}