@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.menuOpen {
  width: 100vw !important;
  margin: 0 !important;
  .hamburger-icon {
    color: #fff !important;
  }

}

.navbar {
  width: calc(100% - 200px);
  padding-top: 20px;
  padding-right: 50px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 90px;
  margin-left: 200px;
  border-bottom: 2px solid $soft-bg;
  z-index: 10000;
  background-color: $main-bg;
  
  @include md {
    width: 100vw !important;
    margin: 0;
    z-index: 10 ;
  }

  .hamburger-icon {
    padding-top: 50px;
    padding-left: 50px; 
    cursor: pointer;
    left: 20px;
    z-index: 10;
    margin-bottom: 90px;
    color: $main-bg
  }

  .menu-settings {
    position: absolute;
    top: 90px;
    right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 150px;
    z-index: 1000;


    ul {
      list-style: none;
      padding: 0;
      color: #111111;
      li {
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
          background-color: #222b3c;
          color: #ffff;
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 10px;
  }

  .menu {
    margin-top: 90px;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 20px;


    .icon {
      cursor: pointer;
    }

    .notification {
      position: relative;

      span {
        background-color: red;
        color: white;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;

      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        object-fit: cover;

      }
    }
  }
}