@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.sale {

    .button {
        width: 150px;
        background-color: $buttom-color;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 21px;
    }

    .button:hover {
        background-color: #f560e3;
    }

    .info {
        display: flex;
        align-items: center;
        gap: 20px;

        .information-sale {
            display: flex;
            flex-direction: row;
            gap: 20px;


            @include md {
                flex-direction: column;
            }

            .radio-group {
                margin-left: 20px;
                display: flex;
                gap: 20px;
                align-items: center;
            }

            .order-number .form-control {
                @include md {
                    margin-left: 7px;
                    width: 90vw;
                }
            }
        }

        .form-control {
            outline: none;
            width: 150px;
            padding: 10px;
            border-radius: 4px;
            background-color: #fff;
            color: #000;
            font-size: 19px;
            border: none;
            height: 45px !important;

            @include md {
                width: 90vw;
            }
        }

        .radio-group label {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #333;
            cursor: pointer;
            background-color: #f0f0f0;
            padding: 10px 20px;
            border-radius: 5px;
            border: 2px solid transparent;
            transition: background-color 0.3s, border-color 0.3s;
        }

        .radio-group input[type="radio"] {
            display: none;
        }

        .radio-group input[type="radio"]:checked+label {
            background-color: $buttom-color;
            color: white;
            font-weight: 500;
            border-color: $buttom-color;
        }

        .radio-group label:hover {
            background-color: #e0e0e0;
        }



        h1 {
            margin-top: 10px;
        }

        button {
            padding: 5px;
            cursor: pointer;
        }

        .forms-new-sale {
            display: flex;
            flex-direction: row;

            h3 {
                color: white;
            }

            @include md {
                display: flex;
                flex-direction: column-reverse;
                width: 98vw;
            }

            .right {
                padding-left: 10px;
                padding-top: 10px;
                padding-bottom: 10px;

                .info-customer,
                .info-items {
                    padding-left: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border: $border-color solid 1px;
                }

                .info-items {

                    margin-top: 10px;

                }
            }

            .left {
                border: $border-color solid 1px;
                margin-left: 10px;
                margin-bottom: 10px;
                margin-top: 10px;
                padding-top: 10px;

                padding-left: 10px;
                h3 {
                    color: white;

                }
            }

        }
    }

    .buttons {
        @include md {
            display: flex;
            flex-direction: column;
        }

        .buttons-edit {
            min-width: 1px !important;
            color: $buttom-color;
        }

        .buttons-deleted {
            color: red;
            min-width: 1px !important;
        }
    }
}