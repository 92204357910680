@import "../../styles/responsive.scss";

.CircularProgress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 190px);
    width: calc(100vw - 250px);
}

@include md {
    .CircularProgress {
        width: calc(100vw);
    }
}