@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";
@import "~react-datepicker/dist/react-datepicker.css";



.form {
  display: flex;
  padding: 20px;
  // padding-left: 100px;
  color: #fff;

  .button-hide {
    display: none;
  }

  @include md {
    padding-left: 7px;
    // padding-top: 200px;
  }



  .modal {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 200px;
    justify-content: start;
    align-items: start;

    @include md {
      flex-direction: column;
      // height: 100vh;
      gap: 100px;
    }

    h1 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
      grid-column: span 2;
    }

    .modal-form {
      display: flex;
      flex-direction: column;


      h1 {
        font-size: 29px;

      }

      form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        flex-grow: 1;

        @include md {
          display: flex;
          flex-direction: column;

        }


        .item {
          display: flex;
          flex-direction: column;
          margin: 0;


          .input-container {
            display: flex;
            flex-direction: row;
            gap: 10px;

        
          


            .buttons-items {
              display: flex;
              gap: 20px;
            }

            .fixed-height-textarea {
              height: 150px !important;
            }

            .button-search {
              margin-top: 3px;
              width: 30px !important;
              height: 40px;
              background-color: $button-hover !important;

              .CircularProgress {
                height: 10px;
                width: 40px;
              }
            }

            @include md {
              display: flex;
              flex-direction: column;

            }
          }

          label {
            font-size: 19px;
            margin-bottom: 5px;
          }

          .checkbox-input,
          .form-control,
          .StyledDatePicker {
            outline: none;
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            background-color: #fff;
            color: #000;
            font-size: 19px;
            border: none;
            height: 45px !important;

            @include md {
              width: 90vw;
            }

            &:focus {
              border-color: $border-color;
              box-shadow: 0 0 0 0.2rem $border-color;
            }

          }

          .select-control {
            color: #000;
            font-size: 19px;
            width: 250px;

            @include md {
              width: 90vw;
            }

          }



          .checkbox-label {
            margin-left: 0.5rem;
            font-size: 1rem;
            color: $soft-color;
            // margin-top: 30px;
          }

          .checkbox-input {
            // margin-top: 30px;
            appearance: none;
            width: 30px;
            height: 10px;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out;

            &:hover {
              border-color: $buttom-color;
            }

            @include md {
              width: 20vw;
            }
          }

          .checkbox-input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
          }

          .checkbox-input:checked {
            background-color: $buttom-color;
          }

          .checkbox-input:focus {
            outline: none;
            box-shadow: 0 0 0 2px $buttom-color;
          }

          .checkbox-container {
            display: flex;
            align-items: center;
            justify-content: center;

            @include md {
              width: 52vw;
            }



          }

          .checkbox-container-edit {
            margin-top: 24px;
          }

        }

        .button {
          grid-column: span 2;
          padding: 10px 20px;
          background-color: $buttom-color;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 10px;
          font-size: 18px;
          height: 60px;

          &:hover {
            background-color: $button-hover;
          }
        }



      }
    }

    .activities {
      overflow-y: auto;
      max-height: 80vh;
      width: 35vw;

      @include md {
        width: 90vw;
      }

      .activity-ul {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .activity-item {
          list-style-type: none;
          position: relative;
          width: 1px;
          background-color: $border-color;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $border-color;
            transform: translateX(-50%);
          }

          div {
            min-width: 480px;
            padding: 15px;
            background-color: #f45b6810;

            @include md {
              min-width: 93vw;

            }

            p {
              margin-bottom: 5px;
            }

            time {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}