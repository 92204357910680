.no-access-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff; 
    
  }
  