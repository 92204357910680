@import "./variables.scss";
@import "./responsive.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color:$main-bg;
}


a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: "Moderustic", sans-serif;
  background-color: $main-bg;
  color: $main-color;
}

.container {
  display: flex;
  margin-left: 200px;
  padding-top: 10px;
  height: 100vh;

  @include md {
    height: 100vh;
    margin-left: 0
  }
}
.container-close{
  margin-left: 0 !important;
}

.menuContainer {
  // width: 250px;
  // padding: 5px 20px;
  border-right: 2px solid $soft-bg;

  @include lg{
    width: max-content;
  }
}

.contentContainer{
  margin-top: 90px;
}

